import React from "react"
import "./testimonial-belt.scss"

import Testimonial from "../Testimonial/testimonial"

import {
  albertHop,
  stevenHasnoot,
  maxKalis,
  matthewKiernander,
  sanneAlbreghs,
} from "../../constants/testimonial-text"

import albert from "../../images/testimonials/alberthop.jpg"
import steven from "../../images/testimonials/stevenhasnoot.jpg"
import sanne from "../../images/testimonials/sannealbreghs.jpg"
import max from "../../images/testimonials/maxkalis.jpg"
import matthew from "../../images/testimonials/matthewkiernander.jpg"

const testimonialData = [
  {
    imageRef: albert,
    imageName: albertHop.albertHopName,
    jobSpec: albertHop.albertHopJobSpec,
    recommendationText: albertHop.albertHopRecommendation,
  },
  {
    imageRef: steven,
    imageName: stevenHasnoot.stevenHasnootName,
    jobSpec: stevenHasnoot.stevenHasnootJobSpec,
    recommendationText: stevenHasnoot.stevenHasnootRecommendation,
  },
  {
    imageRef: sanne,
    imageName: sanneAlbreghs.sanneAlbreghsName,
    jobSpec: sanneAlbreghs.sanneAlbreghsJobSpec,
    recommendationText: sanneAlbreghs.sanneAlbreghsRecommendation,
  },
  {
    imageRef: max,
    imageName: maxKalis.maxKalisName,
    jobSpec: maxKalis.maxKalisJobSpec,
    recommendationText: maxKalis.maxKalisRecommendation,
  },
  {
    imageRef: matthew,
    imageName: matthewKiernander.matthewKiernanderName,
    jobSpec: matthewKiernander.matthewKiernanderJobSpec,
    recommendationText: matthewKiernander.matthewKiernanderRecommendation,
  },
]

const TestimonialCategory = () => {
  return (
    <div className="ghost-testimonial-category">
      {testimonialData.map(
        ({ imageRef, imageName, jobSpec, recommendationText }, index) => {
          return (
            <Testimonial
              img={imageRef}
              altName={imageName}
              jobSpec={jobSpec}
              name={imageName}
              recommendationText={recommendationText}
              key={index}
            />
          )
        }
      )}
    </div>
  )
}

export default TestimonialCategory
