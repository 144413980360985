const albertHopName = "Albert Hop"
const albertHopRecommendation =
  '"I had the pleasure of working with Ashley during his stint at Backbase. Ashley can be described as a very solid asset when it comes to preparing customer cases, presentations and answering RFPs. It is due to his effort and geniality that we have been able to achieve and exceed goals that we have set within the team that we worked in. I would endorse Ashley in his future endeavors and I hope I will have the pleasure again to work with him in the future."'
const albertHopJobSpec = "Senior Partner Manager Latin America - Backbase."

const stevenHasnootName = "Steven Hasnoot"
const stevenHasnootRecommendation =
  "\"You don't see every day such a calm, focused, experienced and well spoken sofware developer as Ashley. His unique personality is a big asset in the super fast world of ever changing software development. Where keeping you wits is key. His experience helped us to lift various approaches of us to the next level and he was keen on sharing that experience to others as well. Up until today i'm still amazed on multitasking various projects while not losing sight of quality, speed, the team and his own unique calmness.\""
const stevenHasnootJobSpec = "Agile Coach at Shop Apotheke Europe."

const maxKalisName = "Max Kalis"
const maxKalisRecommendation =
  '"Ashley & I worked on building a digital prototype at Lloyds Banking Group. Even back then he had a natural understanding of the bigger picture, beyond just the technical aspects. He\'s an asset in terms of not just those critical technical skills but in terms of people, process, planning, profit. "'
const maxKalisJobSpec = "Performance Career Coach."

const matthewKiernanderName = "Matthew Kiernander"
const matthewKiernanderRecommendation =
  "Ashley is your quintessential 'go-to' person. He has a high level of technical skill matched by his ability to clearly communicate to both non technical and technical audiences. Paired with a truely delightful persona made him the first choice for a lot of work going on at Info. The way he approaches problems comes from a much more holistic and big picture mindset which helped us build much more effective solutions. Ashley would be a great asset to any project."
const matthewKiernanderJobSpec = "Developer Advocate - Stack Overflow."

const sanneAlbreghsName = "Sanne Albreghs"
const sanneAlbreghsRecommendation =
  '"I was lucky enough to have met Ashley during the mentorship project of my school, Codam Coding College. He has been a big help to me as a student software developer, offering to take me on as a mentee. It helps me a lot knowing I can connect Ashley when I am in need of advise or just some motivation. I hope you keep sharing your knowledge and positive vibes!"'
const sanneAlbreghsJobSpec =
  "Codam Coding College Software Engineering Student."

export const albertHop = {
  albertHopName,
  albertHopRecommendation,
  albertHopJobSpec,
}

export const stevenHasnoot = {
  stevenHasnootName,
  stevenHasnootRecommendation,
  stevenHasnootJobSpec,
}

export const maxKalis = {
  maxKalisName,
  maxKalisRecommendation,
  maxKalisJobSpec,
}

export const matthewKiernander = {
  matthewKiernanderName,
  matthewKiernanderRecommendation,
  matthewKiernanderJobSpec,
}

export const sanneAlbreghs = {
  sanneAlbreghsName,
  sanneAlbreghsRecommendation,
  sanneAlbreghsJobSpec,
}
