import React from "react"

import "./testimonial.scss"

const Testimonial = ({ img, jobSpec, altName, recommendationText, name }) => {
  return (
    <div className="ghost-testimonial-container">
      <p className="ghost-testimonial-name">{name}</p>
      <div className="ghost-testimonial-image-container">
        <img src={img} alt={altName} />
      </div>
      <p className="ghost-testimonial-job-spec">{jobSpec}</p>
      <p className="ghost-testimonial-recommendation-txt">
        {recommendationText}
      </p>
    </div>
  )
}

export default Testimonial
